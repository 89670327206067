import { useLocalStorage } from "react-use";
import { SKYLARK_PREFERRED_APP } from "../constants/localStorage";
import { CMS_URL } from "../constants/urls";
import { App, PreferredApp } from "../interfaces/Apps";

/**
 * usePreferredApp()
 *
 * Hook to fetch and set the user's preferred app from local storage
 *
 * @returns {PreferredApp} the name and href of the user's preferred app
 */
export default function usePreferredApp(): [
  PreferredApp | undefined,
  typeof setWrapper
] {
  const [preferredApp, setPreferredApp] = useLocalStorage<PreferredApp>(
    SKYLARK_PREFERRED_APP
  );

  const setWrapper = (name?: App): void => {
    switch (name) {
      case "classic-cms":
        setPreferredApp({
          name,
          href: CMS_URL,
        });
        break;
      default:
        setPreferredApp(undefined);
    }
  };

  return [preferredApp, setWrapper];
}
