import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@skylark/react";
import { NextSeo } from "next-seo";
import type { AppProps } from "next/app";
import { Layout } from "../components/layout";
import { PathProtector } from "../components/path-protector";
import { Messages } from "../constants/messages";
import { AuthProvider } from "../hooks/useAuth";

const canonical = process.env.NEXT_PUBLIC_LAUNCHER_URL as string;
const loginPageImageUrl = new URL("/images/skylark-login.jpg", canonical);

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <AuthProvider>
      <ChakraProvider resetCSS theme={theme}>
        <NextSeo
          canonical={canonical}
          description={Messages.SKYLARK_LAUNCHER_DESCRIPTION}
          openGraph={{
            url: canonical,
            title: Messages.SKYLARK_LAUNCHER,
            description: Messages.SKYLARK_LAUNCHER_DESCRIPTION,
            images: [
              {
                url: loginPageImageUrl.toString(),
                width: 1200,
                height: 630,
                alt: "Skylark Login Screen",
                type: "image/jpeg",
              },
            ],
            site_name: "Skylark",
          }}
          title={Messages.SKYLARK_LAUNCHER}
        />
        <Layout>
          <PathProtector>
            <Component {...pageProps} />
          </PathProtector>
        </Layout>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default MyApp;
