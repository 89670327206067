// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://f000097456584d94bf772f51673af11a@o732961.ingest.sentry.io/6204515",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // @TODO determine environment correctly
    environment: "test",
  });
}
