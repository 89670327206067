export const CMS_URL = process.env.NEXT_PUBLIC_CMS_URL as string;
export const API_PREFIX = process.env.NEXT_PUBLIC_API_URL_PREFIX as string;

export const PUBLIC_PATHS = [
  "/login",
  "/login/forgot-confirm",
  "/login/forgot",
  "/login/newuser",
  "/login/set-password-success",
];
