export interface LaunchPadItem {
  description?: string;
  href: string;
  onClick?: () => void;
  icon: string;
  title: string;
  type: "app" | "other";
  external?: boolean;
}

export interface LaunchPadBaseProps {
  items: LaunchPadItem[];
  backHref: string;
  backText: string;
}

interface ILaunchPadConfigParams {
  classicCmsUrl?: string;
  classicCmsOnClick?: () => void;
  launcherUrl?: string;
}

export const createLaunchPadConfig = ({
  classicCmsUrl,
  classicCmsOnClick,
  launcherUrl,
}: ILaunchPadConfigParams): LaunchPadBaseProps => {
  const items: LaunchPadItem[] = [
    {
      href: classicCmsUrl || "",
      onClick: classicCmsOnClick,
      icon: "skylarkClassic",
      title: "Skylark Classic",
      type: "app",
    },
    {
      href: "https://developers.skylarkplatform.com/api/skylark_api.html",
      icon: "skylarkApi",
      title: "API Documentation",
      type: "other",
      external: true,
    },
    {
      href: `${launcherUrl || ""}/users`,
      icon: "userManagement",
      title: "User Management",
      type: "other",
    },
  ];

  const config = {
    items,
    backHref: `${launcherUrl || "/"}?preferred_app=false`,
    backText: "Back to launcher",
  };

  return config;
};
