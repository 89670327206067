import React from "react";
import { ComponentWithAs, Flex, IconProps, Text } from "@chakra-ui/react";
import {
  SkylarkLogoIconWhite,
  SkylarkClassicIconLight,
  SkylarkExperienceIcon,
} from "@skylark/icons";
import { Spinner } from "../spinner";

export type LoadingScreenVariant = "classic" | "experience" | "launcher";

interface ILoadingScreenProps {
  variant?: LoadingScreenVariant;
}

interface IStyles {
  bgColor: string;
  spinnerColor: string;
  spinnerShadowColor: string;
  iconSize: string;
  iconFill?: string;
  textColor?: string;
  textSize?: string;
  textMarginTop?: number;
}

const getStyles = (variant: LoadingScreenVariant): IStyles => {
  switch (variant) {
    case "classic":
      return {
        bgColor: "#4E5868",
        spinnerColor: "#FFF",
        spinnerShadowColor: "rgba(0,0,0,0.3)",
        iconSize: "20",
        iconFill: "#FFF",
        textColor: "#FFF",
        textSize: "5xl",
        textMarginTop: 8,
      };

    case "experience":
      return {
        bgColor: "#E6E9EF",
        spinnerColor: "#0E1825",
        spinnerShadowColor: "#226DFF",
        iconSize: "24",
        textColor: "#0E1825",
        textSize: "5xl",
      };

    // Launcher is the default
    default:
      return {
        bgColor: "#226DFF",
        spinnerColor: "#FFF",
        spinnerShadowColor: "#0E1825",
        iconSize: "28",
        textColor: "#FFF",
        textSize: "9xl",
      };
  }
};

const getIcon = (
  variant: LoadingScreenVariant
): ComponentWithAs<"svg", IconProps> => {
  switch (variant) {
    case "classic":
      return SkylarkClassicIconLight;
    case "experience":
      return SkylarkExperienceIcon;
    default:
      return SkylarkLogoIconWhite;
  }
};

const getText = (
  variant: LoadingScreenVariant
): string | JSX.Element | null => {
  switch (variant) {
    case "classic":
      return (
        <>
          {`Skylark`}
          <Text
            as="span"
            fontFamily="body"
            fontWeight="normal"
            ml="2"
          >{`Classic CMS`}</Text>
        </>
      );
    case "experience":
      return (
        <>
          {`Skylark`}
          <Text
            as="span"
            fontFamily="body"
            fontWeight="normal"
            ml="1"
          >{`CMS`}</Text>
        </>
      );
    default:
      return "Skylark";
  }
};

export const LoadingScreen: React.FC<ILoadingScreenProps> = ({
  variant = "launcher",
}) => {
  const styles = getStyles(variant);
  const Icon = getIcon(variant);
  const text = getText(variant);

  return (
    <Flex
      alignItems="center"
      bgColor={styles.bgColor}
      bottom={0}
      data-test="loading-screen"
      flexDir="column"
      justifyContent="center"
      left={0}
      pos="fixed"
      right={0}
      top={0}
      zIndex={1000}
    >
      <Icon fill={styles.iconFill} h={styles.iconSize} w={styles.iconSize} />
      {text && (
        <Text
          color={styles.textColor}
          fontFamily="heading"
          fontSize={styles.textSize}
          fontWeight="bold"
          mt={styles.textMarginTop || 8}
        >
          {text}
        </Text>
      )}
      <Flex mt="16">
        <Spinner
          color={styles.spinnerColor}
          shadowColor={styles.spinnerShadowColor}
        />
      </Flex>
    </Flex>
  );
};

export default LoadingScreen;
