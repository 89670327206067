export enum ErrorMessages {
  INVALID_LOGIN = "Sorry, you entered an invalid login. Please try again, or request a password reset",
  PW_RESET_REQUIRED = "Account locked. Check your mailbox to reset your password.",
  PW_MISMATCH = "Passwords don't match",
  MISSING_USERNAME_CODE = "Missing username or code in URL",
  GENERIC_ERROR = "Something went wrong, please try again.",
  USER_GENERIC = "Something went wrong while updating the user, please try again.",
  USER_DELETION = "Something went wrong while deleting the user, please try again.",
  USER_FETCHING = "Something went wrong while fetching the users.",
  USER_PW_RESET = "Something went wrong while requesting a password reset, please try again.",
  MISSING_ALL_INPUTS = "Please fill in all the fields",
}

export enum Messages {
  YES = "Yes",
  NO = "No",
  FIRST_NAME = "First name",
  LAST_NAME = "Last name",
  EMAIL = "Email",
  PW = "Password",
  DELETE = "Delete",
  DELETING = "Deleting",
  CANCEL = "Cancel",
  SAVE = "Save",
  CREATING = "Creating",
  UPDATING = "Updating",
  REQUESTED = "Requested",
  TEXT_INPUT_PLACEHOLDER = "Type here",
  FORGOT_PW_SUCCESS = "An email has been sent. Check your mailbox to reset your password.",
  FORGOT_PW_INFO = "We will send you an email to set or reset your new password.",
  FORGOT_PW_HEADING = "Forgotten password",
  FORGOT_PW_BUTTON = "Send email",
  FORGOT_PW_BACK = "Back",
  SET_PW_HEADING = "Set new password",
  SET_PW_BUTTON = "Set password",
  SIGN_IN_REMEMBER_ME = "Remember me on this device",
  SIGN_IN_BUTTON = "Sign in",
  SIGN_IN_FORGOT_PW = "Forgot password?",
  SIGN_IN_HEADING = "Sign in",
  SET_PW_SUCCESS_HEADING = "Your password has been changed successfully!",
  SET_PW_SUCCESS_BUTTON = "You'll be redirected to Skylark",
  SKYLARK_LAUNCHER = "Skylark Launcher",
  SKYLARK_LAUNCHER_DESCRIPTION = "",
  LAUNCHER_LOGOUT = "Sign Out",
  LAUNCHER_HEADING = "Skylark CMS",
  LAUNCHER_ITEM_CMS = "Classic CMS",
  LAUNCHER_ITEM_CMS_DESCRIPTION = "Ingest, upload your content library & enhance your metadata.",
  LAUNCHER_ITEM_API = "API Documentation",
  LAUNCHER_ITEM_API_DESCRIPTION = "Skylark API documentation.",
  LAUNCHER_ITEM_USER_MANAGEMENT = "User Management",
  LAUNCHER_ITEM_USER_MANAGEMENT_DESCRIPTION = "Create, update and delete users.",
  NOT_FOUND = "404",
  NOT_FOUND_DESCRIPTION = "We are sorry - we can't find the page you are looking for.",
  NOT_FOUND_BUTTON = "Take me to the homepage",
  USER_MANAGEMENT_HEADING = "User management",
  USER_MANAGEMENT_CREATE_NEW = "Create new user",
  USER_MANAGEMENT_MODAL_HEADING = "User information",
  USER_MANAGEMENT_PW_RESET_REQUEST = "Send password reset",
  USER_MANAGEMENT_DELETE_USER_CONFIRM = "Are you sure you want to delete this user?",
}

export const AllMessages = {
  ...ErrorMessages,
  ...Messages,
};
