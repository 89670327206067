import React from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  UseDisclosureProps,
} from "@chakra-ui/react";
import { LaunchPadBaseProps } from "@skylark/config";
import { LauncherItem } from "../launcher-item";
import { LauncherBackButton } from "../launcher-back-button";

export type LaunchPadProps = UseDisclosureProps & LaunchPadBaseProps;

export const LaunchPad: React.FC<LaunchPadProps> = ({
  children,
  items,
  backHref,
  backText,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(props);

  return (
    <>
      <Box
        cursor="pointer"
        data-test="open-launch-pad"
        w="100%"
        onClick={onOpen}
      >
        {children}
      </Box>

      <Modal
        autoFocus={false}
        data-test="launch-pad"
        isOpen={isOpen}
        variant="launcherPad"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          left={0}
          ml={{ base: 0, sm: 4 }}
          mt="4"
          pos="fixed"
          top={0}
        >
          <ModalBody
            borderRadius="4px"
            boxShadow="0px 14px 22px -7px rgba(12, 17, 64, 0.4)"
            paddingInline="0"
            py="4"
          >
            <LauncherBackButton
              fontFamily="Inter"
              href={backHref}
              pr="6"
              text={backText}
            />
            {items
              .sort((a, b) => (a.type > b.type ? 1 : -1))
              .map((item, index) => {
                const divider =
                  index > 0 &&
                  items[index - 1].type === "app" &&
                  item.type === "other";

                // Wrap the onClick function to close the modal - helps with native app routing
                let onClick;
                if (item && !item.href && item.onClick) {
                  onClick = () => {
                    if (item.onClick) item.onClick();
                    onClose();
                  };
                }

                return (
                  <React.Fragment key={`launch-pad-item-${item.title}`}>
                    {divider && <Box borderTop="1px solid #E9EBED" my="4" />}
                    <LauncherItem
                      key={index}
                      pb="2"
                      pt={index === 0 ? "0" : "2"}
                      px="6"
                      {...item}
                      isExternal={item.external}
                      variant="launch-pad"
                      onClick={onClick || onClose}
                    />
                  </React.Fragment>
                );
              })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LaunchPad;
