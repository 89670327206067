import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import { isUserAuthenticated, getIdToken } from "../lib/cognito";

interface IAuthContext {
  authenticated: boolean | undefined;
  setAuthenticated: Dispatch<SetStateAction<boolean | undefined>>;
  token: string;
}

const AuthContext = createContext<IAuthContext>({
  authenticated: undefined,
  setAuthenticated: () => undefined,
  token: "",
});

export const AuthProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(
    undefined
  );
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    isUserAuthenticated()
      .then((userIsAuthenticated) => {
        if (authenticated !== userIsAuthenticated)
          setAuthenticated(userIsAuthenticated);
        void getIdToken().then((idToken) => setToken(idToken));
      })
      .catch(() => {
        if (authenticated) setAuthenticated(false);
      });
  }, [router.asPath]);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, token }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * useAuth()
 *
 * Hook to determine if the user is authenticated
 * Rechecks status on a route change
 *
 * @returns {IAuthContext} whether the user is logged in
 * Will return undefined before initial authentication check is complete.
 */
export const useAuth = () => useContext(AuthContext);
