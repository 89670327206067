import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { LoadingScreen } from "@skylark/react";
import usePreferredApp from "../../hooks/usePreferredApp";
import { useAuth } from "../../hooks/useAuth";
import { PUBLIC_PATHS } from "../../constants/urls";

const pathsToNotRedirectTo = [...PUBLIC_PATHS, "/logout", "/"];

export const PathProtector: React.FC = ({ children }) => {
  const router = useRouter();
  const { authenticated: isAuthenticated } = useAuth();
  const runningInitialAuthCheck = isAuthenticated === undefined;

  const [authorized, setAuthorized] = useState<boolean>(false);
  // We want to hide the loading screen when navigating between local pages
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true);
  const [preferredApp] = usePreferredApp();

  const validatePath = (url: string) => {
    const [path] = url.split("?");

    if (runningInitialAuthCheck) {
      setAuthorized(false);
      setShowLoadingScreen(true);
    } else if (!isAuthenticated && !PUBLIC_PATHS.includes(path)) {
      // Private page check
      // Redirect to login page if unauthenticated and accessing a private page
      setAuthorized(false);
      setShowLoadingScreen(false);
      const query = pathsToNotRedirectTo.includes(path)
        ? ""
        : `redirect=${router.asPath}`;

      void router.push({
        pathname: "/login",
        query,
      });
    } else if (isAuthenticated && path === "/login") {
      // Login page check
      // Stop logged in users accessing the login screen and show loading spinner
      setAuthorized(false);
      setShowLoadingScreen(true);

      const bypassPreferredApp = router.query?.preferred_app === "false";
      void router.push(
        preferredApp && !bypassPreferredApp ? preferredApp.href : "/"
      );
    } else {
      setAuthorized(true);
      setShowLoadingScreen(false);
    }
  };

  useEffect(() => {
    // Run auth check on first load and when user state changes
    void validatePath(router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on("routeChangeStart", hideContent);

    const validatePathWrapper = (url: string) => {
      void validatePath(url);
    };

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", validatePathWrapper);

    return () => {
      router.events.off("routeChangeStart", hideContent);
      router.events.off("routeChangeComplete", validatePathWrapper);
    };
  }, [isAuthenticated]);

  return (
    <>
      {authorized && children}
      {(runningInitialAuthCheck || (!authorized && showLoadingScreen)) && (
        <LoadingScreen variant="launcher" />
      )}
    </>
  );
};

export default { PathProtector };
