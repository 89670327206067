import { Box, Flex, Text } from "@chakra-ui/react";
import { createLaunchPadConfig } from "@skylark/config";
import { SkylarkLogoIconWhite } from "@skylark/icons";
import { LaunchPad } from "@skylark/react";
import { useRouter } from "next/router";
import { CMS_URL } from "../../constants/urls";

const launchPadProps = createLaunchPadConfig({
  classicCmsUrl: CMS_URL,
});

export const Layout: React.FC = ({ children }) => {
  const router = useRouter();
  return (
    <>
      {router.asPath === "/users" ? (
        <Box bg="skylarkBlue">
          <LaunchPad {...launchPadProps}>
            <Flex alignItems="center" px={{ base: 4, md: 6 }} py="4">
              <SkylarkLogoIconWhite h="6" w="6" />
              <Text color="white" fontFamily="heading" ml="2">{`Skylark`}</Text>
            </Flex>
          </LaunchPad>
        </Box>
      ) : (
        <Box bg="skylarkBlack" h="launcher-h-header" px="4" />
      )}
      {children}
    </>
  );
};

export default { Layout };
